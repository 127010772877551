import ChevronLeftIcon from '@heroicons/react/24/outline/ChevronLeftIcon';
import { Button } from 'atp-react-ui';

import { ROUTES } from '@/_navigation/routes';
import { useDataContext } from '@/_provider';
import DarkLogo from '@/_static/assets/atp_dark_icon.svg?react';
import LightLogo from '@/_static/assets/atp_white_icon.svg?react';

export function Header({ lightLogo, hideBackButton = false }) {
  const { currentObj, currentPage, nav } = useDataContext();

  const handleShowBackButton = () => {
    return currentPage !== ROUTES.QUALIFY.link;
  };

  const handlePageCount = () => {
    const locallyStoredFormData = localStorage.getItem('formData');
    const formData = locallyStoredFormData ? JSON.parse(locallyStoredFormData) : null;

    const arr = new Set();
    const ignoreQuestions = new Set(['ATP_3__c']);
    const ignoreAPImap = new Map([
      ['ATP_5__c', 'ATP_6__c'],
      ['ATP_7__c', 'ATP_8__c'],
      ['ATP_9__c', 'ATP_10__c'],
      ['ATP_11__c', 'ATP_12__c'],
    ]);

    let i = 1;
    while (i < 13) {
      const labelValue = formData[`ATP_${i}__c`];
      if (
        labelValue !== undefined &&
        labelValue &&
        labelValue.includes(`I've passed`) &&
        labelValue !== `I've passed some, but not all of them.`
      ) {
        if (ignoreAPImap.get(`ATP_${i}__c`) != null) {
          ignoreQuestions.add(ignoreAPImap.get(`ATP_${i}__c`));
        }
      }
      i++;
    }

    let j = 1;
    while (j < 13) {
      if (!ignoreQuestions.has(`ATP_${j}__c`)) {
        arr.add(`ATP_${j}__c`);
      }
      j++;
    }
    const nOfQuestions = arr.size;
    let nOfQuestionsCompleted = 0;

    if (formData) {
      for (const [key, value] of Object.entries(formData)) {
        if (arr.has(key) && value !== '') {
          nOfQuestionsCompleted++;
        }
      }
    }
    return [nOfQuestionsCompleted, nOfQuestions];
  };

  const pageCount = handlePageCount();

  const handleRenderPageCount = () => {
    let html = null;
    if (currentObj?.checkout) {
      html = null;
    } else if (currentObj?.step) {
      html = currentObj?.step;
    } else if (currentObj?.main || currentObj?.hidePageCount) {
      html = null;
    } else {
      const n_pageCount = pageCount[0] === 0 ? 1 : pageCount[0];
      html = `question ${n_pageCount}/${pageCount[1]}`;
    }
    return html;
  };

  const showBackButton = handleShowBackButton();
  const renderPageCount = handleRenderPageCount();
  const showDefaultProgress = currentObj?.defaultProgress;

  return (
    <header className="flex h-16 justify-between">
      <div>
        {showBackButton && !currentObj?.hideBackButton && !hideBackButton && (
          <Button
            as="link"
            theme="primary"
            className="hover:bg-accent-light/10 focus:ring-0 disabled:border-primary-light disabled:bg-primary-light disabled:text-primary-medium"
            onClick={() => nav(-1)}
            iconLeft={ChevronLeftIcon}
          >
            {renderPageCount && renderPageCount !== ' ' && (
              <div className={!showDefaultProgress ? 'text-secondary' : ''}>{renderPageCount}</div>
            )}
          </Button>
        )}
      </div>

      <div>
        {currentObj?.lightLogo || lightLogo ? (
          <LightLogo className="w-7 md:w-10" />
        ) : (
          <DarkLogo className="w-7 md:w-10" />
        )}
      </div>
    </header>
  );
}
