import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { ErrorBoundary } from '@/_components';
import { ROUTES } from '@/_navigation/routes';
import BridgeQualify from '@/_pages/bridge-plan';
import { ChatPage } from '@/_pages/chat';
import FinalScreen from '@/_pages/final-screen';
import { MeetingConfirmationPage } from '@/_pages/meeting-confirmation';
import RedirectResolver from '@/_pages/redirect-reolver';
import RedirectToMeetNow from '@/_pages/redirecting-to-meet';
import { Administrator } from '@/_provider/administrator';

import VideoPage from './_pages/video-page';
import { LeadProvider, DataProvider } from './_provider';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ErrorBoundary />}>
      <Route path={ROUTES.CONFIRMATION.link} element={<MeetingConfirmationPage />} />
      <Route path={ROUTES.CHAT.link} element={<ChatPage />} />
      <Route element={<LeadProvider />}>
        <Route element={<DataProvider />}>
          <Route element={<Administrator />}>
            <Route path={ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_THANKS.link} element={<FinalScreen />} />
            <Route path={ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_SOON_THANKS.link} element={<FinalScreen />} />
            <Route path={ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_THANKS.link} element={<FinalScreen />} />

            <Route path={ROUTES.REDIRECT_TO_MEET_NOW.link} element={<RedirectToMeetNow />} />

            {/* VideoPage  */}
            {[ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_VIDEO.link, ROUTES.MINDSET_VIDEO.link].map((route) => (
              <Route key={route} path={route} element={<VideoPage />} />
            ))}

            {Object.values(ROUTES).map((route) => {
              if (route?.isNotBp) {
                return null;
              } else {
                return <Route key={route.link} path={route.link} element={<BridgeQualify />} />;
              }
            })}

            <Route path="*" element={<RedirectResolver />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

export default router;
