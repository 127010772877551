import { useEffect, useRef, useState } from 'react';

import { initChat, openChatWindow, setFullScreenUI, openProActiveChat } from '@/_utils';

export function ChatPage() {
  const [state, setState] = useState(false);

  useEffect(() => {
    initChat(() => {
      console.log('Chat initialized');
      setState(true);
      console.log('Starting Proactive chat');
      openProActiveChat();

      console.log('Opening chat');
      openChatWindow();

      console.log('Setting window UI');
      setFullScreenUI();
    });
  }, [setState]);

  const refChatWindow = useRef(false);

  useEffect(() => {
    if (state) {
      const handleMsg = (event) => {
        console.log('Message received', event);
        if (
          event.origin === 'https://web-modules-de-na1.niceincontact.com' &&
          event.data.actionType === 'MESSAGE_RECEIVED' &&
          !refChatWindow.current
        ) {
          setFullScreenUI();
          openChatWindow();
          refChatWindow.current = true;
        }
      };
      window.addEventListener('message', handleMsg, false);
      return () => {
        window.removeEventListener('message', handleMsg);
      };
    }
  }, [state]);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <h1>LOADING...</h1>
    </div>
  );
}
