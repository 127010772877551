/**
 * BrandEmbassy Chat Manager
 * Encapsulates all chat functionality for better state management and API consistency
 */

const isChat = window.location.href.pathname === '/chat';

class ChatManager {
  constructor() {
    this.isAllowed = true;
    this.initialized = false;
    this.proactiveSessionInit = false;
    this.proactiveChatAttempts = 0;
  }

  /**
   * Initialize the chat integration
   * @param {Function} callback - Function to call when chat is ready
   */
  init(callback) {
    if (window.brandembassy || !this.isAllowed) {
      return;
    }

    this._loadScript((success) => {
      if (success && callback) callback();
    });
    if (!isChat) {
      this.hideButton();
    }
  }

  /**
   * Load the BrandEmbassy chat script
   * @private
   */
  _loadScript(onReady) {
    (function (n, u) {
      window.BrandEmbassy = n;
      window[n] =
        window[n] ||
        function () {
          (window[n].q = window[n].q || []).push(arguments);
        };
      window[n].u = u;

      const e = document.createElement('script');
      e.async = true;
      e.src = `${u}?${Math.round(Date.now() / 1e3 / 3600)}`;

      e.onload = () => {
        if (window.readyState && window.readyState !== 'loaded' && window.readyState !== 'complete') {
          return;
        }

        window.brandembassy('init', 4273, 'chat_144b60d3-25ff-4b47-9c97-7f727c2f9e95');

        const checkButton = setInterval(() => {
          const btn = document.getElementById('be-chat-container');

          if (btn) {
            clearInterval(checkButton);
            window.brandembassy('setAllowedExternalMessageTypes', ['MESSAGE_RECEIVED']);
            chatManager.initialized = true;
            if (!isChat) {
              chatManager.hideButton();
            }

            if (onReady) onReady(true);
          } else {
            if (!isChat) {
              chatManager.hideButton();
            }
          }
        }, 300);
      };

      e.addEventListener('onreadystatechange', e.onload);
      document.head.appendChild(e);
    })('brandembassy', 'https://livechat-static-de-na1.niceincontact.com/4/chat.js');
  }

  /**
   * Execute a chat command safely with error handling
   * @private
   */
  _executeSafely(action, ...args) {
    if (!this.initialized) return false;

    try {
      action(...args);
      return true;
    } catch (err) {
      console.error(`Chat error in ${action.name}:`, err);
      return false;
    }
  }

  // User information methods
  setEmail() {
    if (!this.initialized) return false;

    // Uncomment this when needed
    // return this._executeSafely(() => window.brandembassy('setCustomerEmail', email));
    return true;
  }

  setUsername(username) {
    return this._executeSafely(() => window.brandembassy('setCustomerName', username));
  }

  setUserInfo(email, username) {
    if (!this.initialized) return false;
    this.setEmail(email);
    return this.setUsername(username);
  }

  // Window control methods
  hideWindow() {
    return this._executeSafely(() => window.brandembassy('hideChatWindow'));
  }

  openWindow() {
    this.showButton();
    return this._executeSafely(() => window.brandembassy('openChatWindow'));
  }

  hideButton() {
    if (!this.initialized) return false;
    try {
      this.hideWindow();
      const btn = document.getElementById('be-chat-container');
      if (btn) btn.style.display = 'none';
      return true;
    } catch (err) {
      console.error('Error hiding chat button:', err);
      return false;
    }
  }

  showButton() {
    if (!this.initialized) return false;

    try {
      const btn = document.getElementById('be-chat-container');
      if (btn) btn.style.display = 'block';
      return true;
    } catch (err) {
      console.error('Error showing chat button:', err);
      return false;
    }
  }

  // Proactive chat methods
  openProactiveChat(firstName) {
    if (!this.initialized) return false;

    const result = this._startProactiveChat(firstName);
    if (!result) {
      setTimeout(() => this._startProactiveChat(firstName), 500);
    }
    return true;
  }

  _startProactiveChat(firstName) {
    if (!this.initialized) return false;

    this.proactiveChatAttempts++;

    if (!this.setUsername(firstName)) {
      return false;
    }

    try {
      window.brandembassy('hidePreSurvey');

      if (!this.proactiveSessionInit) {
        this.proactiveSessionInit = true;
        window.brandembassy('sendFirstMessageAutomatically', 'New conversation');
        window.brandembassy('autoStartSession');
        this.hideWindow();
      } else {
        this.openWindow();
      }
      return true;
    } catch (err) {
      console.error('Error starting proactive chat:', err);
      return false;
    }
  }

  // UI customization
  setFullScreenUI() {
    return this._executeSafely(() => {
      window.brandembassy('setFullDisplay');
      window.brandembassy(
        'setCustomCss',
        '[data-cy="header-minimize-window"] { display: none} [data-selector="HEADER"] { border-radius: 0 !important}'
      );
    });
  }
}

// Create singleton instance
const chatManager = new ChatManager();

// Export public API functions
export function initChat(callback) {
  return chatManager.init(callback);
}

export const setChatEmail = (email) => chatManager.setEmail(email);
export const setChatUsername = (username) => chatManager.setUsername(username);
export const setChatNameEmail = (email, username) => chatManager.setUserInfo(email, username);
export const hideChatWindow = () => chatManager.hideWindow();
export const openChatWindow = () => chatManager.openWindow();
export const hideChatButton = () => chatManager.hideButton();
export const showChatButton = () => chatManager.showButton();
export const openProActiveChat = (firstName) => chatManager.openProactiveChat(firstName);
export const setFullScreenUI = () => chatManager.setFullScreenUI();

// Removed the commented out chatCtrl function as it appeared unused
