import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Article, Footer, Header, Section, Container, Main } from '@/_containers';
import CATEGORIES from '@/_mappers/category-mapper';
import GOOGLE_EVENT_MAPPING from '@/_mappers/google-mappers';
import { ROUTES } from '@/_navigation/routes';
import { useDataContext, useLeadContext, useAjaxHandler } from '@/_provider';
import { TZ } from '@/_static/constants';
import { buildScoreMap, updateLocalStorage, openWindow, fixUniqueStages } from '@/_utils';
import { snackbar } from '@/_utils/snackbar-actions';

import CalendlyView from './components/CalendlyView';
import Checkout from './components/Checkout';
import CustomBodyCard from './components/CustomBodyCard';
import FormCard from './components/FormCard';
import { contactSchema } from './components/FormCard/contact-card-schema';
import InformativeCard from './components/InformativeCard';
import MainCard from './components/MainCard';
import PageCard from './components/PageCard';
import ResultsCard from './components/ResultsCard';

export default function BridgeQualify() {
  const {
    nav,
    dev_setPortal,
    currentPage,
    currentObj,
    currentQuestions,
    sendGoogleEvent,
    isValidTimeToMeet,
    isLoading,
    setIsLoading,
    setEnableButton,
    updateCategory,
    formState: [formData, setFormData],
  } = useDataContext();
  const { navigate, data, noLead } = useLeadContext();
  const { l: leadObj } = data;

  const props = {
    navigate,
    currentObj,
    currentPage,
    leadObj,
    currentQuestions,
    sendGoogleEvent,
  };

  // const { mutateAsync: mutateRetelAiAsync, isPending: isPendingRetelAi } = useRetellAI(leadObj.Id);

  const { mutateAsync: mutateLeadQualification, isPending: isPendingLeadQualification } = useAjaxHandler();

  const { mutateAsync: mutateUpdateLead } = useAjaxHandler();

  const form = useForm({
    resolver: zodResolver(contactSchema),
    mode: 'onChange',
    defaultValues: {
      email: leadObj.Email__c ?? '',
      phone: (leadObj.Mobile_Primary__c || '').split('-').join(''),
    },
  });

  const isPending = /* isPendingRetelAi || */ isPendingLeadQualification;

  const handleGoogleEvent = (page, isSingle = false) => {
    const ui = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : null;
    const uiArr = ui?.ga_single_events ? ui.ga_single_events : [];
    const googleEvent = GOOGLE_EVENT_MAPPING[page];

    if (isSingle) {
      if (!uiArr.includes(page)) {
        uiArr.push(page);
        updateLocalStorage('ui', { ga_single_events: uiArr });
        sendGoogleEvent(googleEvent);
      }
    } else {
      sendGoogleEvent(googleEvent);
    }
  };

  const submitLeadQualification = async (onLoad = false, callback) => {
    if (!noLead) {
      let fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;

      const scoreMap = buildScoreMap(fd);
      const fd_stage = fd?.Stage__c ?? null;

      const splitStage = fd_stage ? fd_stage.split(' ') : null;
      let stage_score = splitStage ? `${splitStage.join('_')}_Score__c` : null;
      const fd_score = scoreMap.get(fd_stage);

      if (stage_score && fd_score) {
        if (fd_stage === CATEGORIES.ENTRANCE_EXAM) {
          stage_score = 'Entrance_Exam_Score__c';
        } else if (fd_stage === CATEGORIES.LICENSURE_EXAM || fd_stage === CATEGORIES.EXIT_EXAM) {
          stage_score = 'Exit_Exam_Score__c';
        } else if (fd_stage === CATEGORIES.CORE_RN_COURSES) {
          stage_score = 'Core_Nursing_Courses_Score__c';
        }

        updateLocalStorage('formData', { [stage_score]: fd_score });
      }

      if (fd_stage) {
        const stageArr = fd.Stages_Completed__c.split(';');
        if (!stageArr.includes(fd_stage)) {
          stageArr.push(fd_stage);
        }
        let Calendly_Appointment_Scheduled__c;
        let Reservation_Confirmed_Score__c;
        if (fd_stage === CATEGORIES.READY_SOON_RES_CONFIRMED || fd_stage === CATEGORIES.READY_NOT_YET_RES_CONFIRMED) {
          Reservation_Confirmed_Score__c = 50;
        }
        if (fd_stage === CATEGORIES.READY_SOON_RES_SCHEDULE) {
          Calendly_Appointment_Scheduled__c = true;
        }

        updateLocalStorage('formData', {
          Calendly_Appointment_Scheduled__c,
          Reservation_Confirmed_Score__c,
          Stage__c: fd_stage,
          Stages_Completed__c: fixUniqueStages(stageArr),
        });
      }

      fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
      const campaign = localStorage.getItem('campaign') ? JSON.parse(localStorage.getItem('campaign')) : null;
      const lid = fd.Lead__c;

      const leadInfo = {
        firstName: leadObj.First_Name__c,
        lastName: leadObj.Name,
        email: leadObj.Email__c,
        campaign,
        formName: 'Lead Qualification',
        timezone: TZ,
        lq_app: true,
      };

      const json = {
        action: 'submitLeadQualification',
        leadId: lid,
        userInfo: fd,
        leadInfo,
      };

      if (fd_stage === CATEGORIES.GENERAL_EDUCATION && fd?.ATP_6__c && fd.ATP_6__c !== 'Not important.') {
        json.leadInfo.pilot = true;
        json.updateLead = true;
      }

      if (currentPage === '0_4' || onLoad) {
        json.updateLead = true;
      }

      if (fd_stage === CATEGORIES.READY_NOT_YET_LEARN_HOME) {
        json.updateLead = true;
      }

      mutateLeadQualification(json)
        .then(() => {
          if (!onLoad && currentObj?.meetAction) {
            handleGoogleEvent('reservation_confirmed');
            const ui = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : null;

            if (ui?.currentOptionValue === 'I prefer to schedule at another time.') {
              handleGoogleEvent('ca');
            } else if (ui?.currentOptionValue === 'I prefer to meet with an advisor now.') {
              if (isValidTimeToMeet) {
                handleGoogleEvent('sn');
                if (currentObj?.customKey === 'bpPreBuyReservation') {
                  nav(`${ROUTES.REDIRECT_TO_MEET_NOW.onlyLink}/${leadObj.token__c}`, {
                    state: { from: currentPage, dev_setPortal },
                  });
                } else {
                  nav(ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_CONNECT.link);
                  // if (isMobile.any()) {
                  //   nav(ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_CALL.link);
                  // } else {
                  //   nav(ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_CHAT.link);
                  // }
                }
              } else {
                if (currentObj.customKey === 'bpPreBuyReservation') {
                  nav(ROUTES.BRIDGE_PLAN_PRE_BUY_RES_SCHEDULED.link, {
                    state: { from: currentPage, dev_setPortal },
                  });
                } else {
                  nav(ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_INFO.link, {
                    state: { from: currentPage, dev_setPortal },
                  });
                }
              }
            }
            if (callback) {
              callback();
            }
            return true;
          }

          if (callback) {
            callback();
          }
          return null;
        })
        .catch(() => {
          snackbar.error('Something went wrong please try again');
        });
    }
  };

  const handleSubmitLeadQualification = async (action, callback) => {
    const val =
      action === 'meet_now' ? 'I prefer to meet with an advisor now.' : 'I prefer to schedule at another time.';
    setIsLoading(true);
    updateLocalStorage('formData', { ATP_14__c: val });
    updateLocalStorage('ui', { currentOptionValue: val });

    await submitLeadQualification(false, callback);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const renderCard = () => {
    let comp = null;
    if (currentObj?.pageCard) {
      comp = (
        <PageCard
          {...props}
          isPending={isPending}
          handleSubmitLeadQualification={handleSubmitLeadQualification}
          handleGoogleEvent={handleGoogleEvent}
          // updateCategory={updateCategory}
        />
      );
    } else if (currentObj?.calendlyComponent) {
      comp = (
        <CalendlyView
          {...props}
          submitLeadQualification={submitLeadQualification}
          handleGoogleEvent={handleGoogleEvent}
          updateCategory={updateCategory}
        />
      );
    } else if (currentObj?.checkout) {
      comp = (
        <Checkout
          {...props}
          buttonIsLoading={isLoading}
          submitLeadQualification={submitLeadQualification}
          handleGoogleEvent={handleGoogleEvent}
        />
      );
    } else if (currentObj?.main) {
      console.log('<------------------------ main card ------------------------>');
      comp = <MainCard {...props} />;
    } else if (currentObj?.customBody) {
      console.log('<------------------------ customBody card ------------------------>');
      comp = <CustomBodyCard {...props} />;
    } else if (!currentObj?.main && currentObj?.informative && !currentObj?.results && !currentObj?.videoComponent) {
      console.log('<------------------------ InformativeCard card ------------------------>');
      comp = <InformativeCard {...props} />;
    } else if (currentObj?.informative && currentObj?.results) {
      console.log('<------------------------ ResultsCard card ------------------------>');
      comp = <ResultsCard {...props} />;
    } else {
      console.log('<------------------------ else card ------------------------>');
      comp = <FormCard {...props} form={form} pageCallBack={pageCallBack} setEnableButton={setEnableButton} />;
    }
    return comp;
  };

  const pageCallBack = (key, value) => {
    if (key && value) {
      setFormData({ ...formData, [key]: value });
    }
  };

  const handleClick = async () => {
    if (ROUTES.BRIDGE_EXIT_EXAM_END.link === currentPage) {
      try {
        setIsLoading(true);
        // await mutateRetelAiAsync();
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    }

    setEnableButton(false);
    // this logic handles conditional page redirects and normal page navigation
    let hasCondition = false;
    let redirectPage = null;
    const uiObj = localStorage.getItem('ui') ? JSON.parse(localStorage.getItem('ui')) : {};
    const currentOptionValue = uiObj?.currentOptionValue ? uiObj.currentOptionValue : null;

    let selectedOption = null;
    if (currentObj?.question) {
      for (const options of currentObj.list) {
        if (options?.conditionals && options?.conditionals.length > 0) {
          for (const option of options.conditionals) {
            const { page, redirect, value } = option;
            if (value === currentOptionValue) {
              selectedOption = option;
              hasCondition = true;
              if (redirect) {
                redirectPage = page;
                break;
              }
            }
          }
        }
      }
    }

    if (currentObj.allowCategoryAndUpload) {
      // udpate current stage

      updateCategory(currentObj.category);

      handleGoogleEvent(currentPage);
      setIsLoading(true);
      submitLeadQualification('', () => {
        setIsLoading(false);
        postProcessHandleClick(hasCondition, redirectPage, selectedOption);
      });
      return;
    }

    postProcessHandleClick(hasCondition, redirectPage, selectedOption);
  };

  const postProcessHandleClick = (hasCondition, redirectPage, selectedOption) => {
    if (!hasCondition) {
      if (currentObj?.nextPage === 'HAVE_A_MEETING') {
        handleGoogleEvent('pre_buy_meetNow', true);
        handleSubmitLeadQualification('meet_now');
      } else if (
        currentObj?.nextPage === ROUTES.BRIDGE_PLAN_PRE_BUY_RES.link ||
        currentObj?.nextPage === ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_OPTS.link
      ) {
        setIsLoading(true);
        updateCategory(currentObj?.category);
        submitLeadQualification(false, () => {
          setIsLoading(false);
          navigate(currentObj?.nextPage);
        });
      } else {
        navigate(currentObj?.nextPage);
      }
    } else if (selectedOption?.redirectLinkType === 'external') {
      if (selectedOption?.category) {
        setIsLoading(true);
        updateCategory(selectedOption.category);
        submitLeadQualification(false, () => {
          const link = redirectPage + (selectedOption.openWebWithName ? `?l_name=${leadObj?.First_Name__c}` : '');
          openWindow(link);
        });
      } else {
        openWindow(redirectPage);
      }
    } else if (redirectPage === ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_NOT_YET_RES_SCHEDULED.link) {
      handleGoogleEvent('no_pre_buy_calendly', true);
      setIsLoading(true);
      updateCategory(selectedOption.category);
      handleSubmitLeadQualification('', () => {
        navigate(redirectPage);
        setIsLoading(false);
      });
    } else if (redirectPage === ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_SOON_RES_SCHEDULED.link) {
      handleGoogleEvent('no_pre_buy_calendly', true);
      setIsLoading(true);
      updateCategory(selectedOption.category);
      handleSubmitLeadQualification('', () => {
        navigate(ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_SOON_THANKS.link);
        setIsLoading(false);
      });
    } else if (selectedOption.value === "Immediately. I'm ready to select a plan.") {
      setIsLoading(true);
      updateCategory(selectedOption.category);
      handleGoogleEvent('no_pre_buy_meetNow', true);
      setIsLoading(false);
      // navigate(ROUTES.BRIDGE_PLAN_WO_PRE_BUY_READY_IMMEDIATE_THANKS.link);
      if (isValidTimeToMeet) {
        return handleSubmitLeadQualification('meet_now');
      }
      // off office hours
      handleSubmitLeadQualification('', () => {
        setIsLoading(true);
        updateCategory(selectedOption.offOfficeHoursCategory);
        handleSubmitLeadQualification('meet_now');
      });
    } else if (redirectPage === 'HAVE_A_MEETING') {
      handleGoogleEvent('pre_buy_meetNow', true);
      handleSubmitLeadQualification('meet_now');
    } else {
      navigate(redirectPage);
    }
  };

  const processPhoneNumberValidity = async () => {
    return true;
    // if (!isProductionEnv) {
    //   return true;
    // }
    // let isPhoneValid = leadObj.Mobile_Primary__c.split('-').join('') === phoneNumber.split('-').join('');

    // if (!isPhoneValid) {
    //   try {
    //     const phoneValidationResponse = await validatePhoneNumber({
    //       number: phoneNumber,
    //       country_code: 'US',
    //     });
    //     if (phoneValidationResponse?.Status?.toLowerCase() === 'valid') {
    //       isPhoneValid = true;
    //     } else {
    //       form.setError('phone', { type: 'manual', message: 'Please provide valid phone number' });
    //     }
    //   } catch (e) {
    //     console.error(e);
    //     // snackbar.error('There was an error validating your phone number.');
    //   }
    // }

    // return isPhoneValid;
  };

  const submitLeadContact = async () => {
    setIsLoading(true);
    const fd = localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : null;
    const lid = fd.Lead__c;

    const userInfo = {
      Id: lid,
      Email__c: fd.Email__c,
      Mobile_Primary__c: fd.mobile,
    };

    // Validate phone number
    const isPhoneValid = await processPhoneNumberValidity(userInfo.Mobile_Primary__c);
    if (!isPhoneValid) {
      setIsLoading(false);
      snackbar.error('The phone number you entered is invalid. Please try again.');
      return false;
    }

    try {
      await mutateUpdateLead({
        action: 'udpateLead',
        leadId: lid,
        userInfo,
      });
      return true;
    } catch (e) {
      console.log(e);
      snackbar.error('');
      return false;
    }
  };

  const handleSubmission = async (api) => {
    setIsLoading(true);
    switch (api) {
      case 'contact_form':
        {
          const res = await form.trigger(['email', 'phone']);
          if (!res) {
            setIsLoading(false);
            return;
          }

          const isSuccess = await submitLeadContact();
          if (!isSuccess) {
            return;
          }
          updateCategory(currentObj.category);
          submitLeadQualification(false, () => {
            setIsLoading(false);
            navigate(ROUTES.BRIDGE_RESULTS.link);
          });
        }
        break;

      case 'ATP_13__c':
        submitLeadQualification();
        break;
      default:
        break;
    }
    return null;
  };

  useEffect(() => {
    if (currentPage === ROUTES.QUALIFY.link) {
      handleGoogleEvent('start_qualify');
      submitLeadQualification(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Article>
      <Container className={`${currentObj?.results ? 'results' : ''} ${currentObj.backgroundColor}`}>
        <Section className={currentObj.calendlyComponent ? 'calendly' : ''}>
          <Header />
          <Main className={currentObj.calendlyComponent ? 'calendly' : ''}>{renderCard(currentPage, currentObj)}</Main>
        </Section>
      </Container>
      <Footer handleClick={handleClick} handleSubmission={handleSubmission} />
    </Article>
  );
}
